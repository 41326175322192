.App {
    text-align: center;
}


.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

body {
    background: #282c34;
    color: white;
}

input {
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 200px;
    background: #3a3c3d;
}
.form-control{
    background: #3a3c3d;
    color: white;
}
