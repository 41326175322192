body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    background: #282c34;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container-middle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    width: 100%;
    background-color: #282c34;
    color: white;
    font-size: calc(10px + 2vmin);
    text-align: center;
}

.top {
    align-items: baseline;
}

.container-middle input {
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    border: none;
}

.row {
    display: flex;
}
