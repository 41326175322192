.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 2s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.token-input {
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    width: 85%;
}
