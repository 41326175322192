.chart-container {
  text-align: center;
}

.pie-chart {
  display: inline-block;
}

.leads-values {
  min-width: 199px;
  text-align: left;
  padding: 11px;
  line-height: 29px;
}

.lead-chart{
  max-width: 660px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.date-picker{
  display: flex;
  button {
    height: 40px;
    margin: 10px;
  }
}
.container-middle{
  height: 51vh;
  @media (max-height: 700px) {
    height: 70vh;
  }
  @media (max-height: 525px) {
    height: 90vh;
  }
}
