.modal-content {
    background-color: #282c34;
    color: white;
    min-width: fit-content;
}
.modal-content button {
    color: white;
}

.table-danger>tbody{
    line-height: 1;
}
