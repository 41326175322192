.table{
    font-size: 1vw;
    @media (max-width: 768px) {
        font-size: 1vw;
    }
}
.table>tbody{
    line-height: 4px;
    font-size: 13px;
    @media (max-width: 1071px) {
        line-height: 2px;
        font-size: 1vw;
    }
    @media (max-width: 512px) {
        line-height: .1px;
        font-size: 1vw;
    }
}

tbody>tr>td{
    @media (max-width: 1071px) {
        padding: 1vw!important;
    }
    @media (max-width: 512px) {
        padding: 1vw!important;
    }
}
